import logo from './logo.svg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Carousel from 'react-bootstrap/Carousel';

import Logo from './imagenes/imagenLogo.png'
import image1 from './imagenes/imagen1.jpg'
import imagec1 from './imagenes/imagenc1.jpg'
import imagec2 from './imagenes/imagenc2.jpg'
import imagec3 from './imagenes/imagenc3.jpg'
import imagec4 from './imagenes/imagenc4.jpg'
import imagec5 from './imagenes/imagenc5.jpg'
import imagec6 from './imagenes/imagenc6.jpg'

function App() {
  return (
    <div className="App">
      <div className='seccion1' >
        <Col>
        <img
                        src={Logo}
                        width="12%"
                        height="12%"
                        className="d-inline-block align-top"
                        alt=""
                      />
        </Col>

        <Col>
        Contacto: <a href="mailto:info.factorfiscal@gmail.com">info.factorfiscal@gmail.com</a>
        </Col>
        
      </div>
      <Container className='seccion2'>
        <Row className='rowseccion2'>
          <Col className='text-principal'>
            <p>
            Servicio de consultoría en administración de empresas, 
            capacitaciones de empresas públicas y privadas. 
            Gestión de procesos de contabilidad, finanzas, legales y auditorias.
            </p> 
          </Col>
          <Col>
          <div>
          <img
                    src={image1}
                    width="90%"
                    height="90%"
                    className="d-inline-block align-top"
                    alt=""
                  />
          </div>
          </Col>
        </Row>
      </Container>
      <Container className='seccion3'>
    
          
          <div className='box-mision'>
          <h2>Misión</h2>
          <p className='text'>
          Ser una empresa dedicada a la presentación de servicios<br/> consistentes en la administración de personal,<br/>
          servicios legales y contables, proporcionando a nuestros<br/> clientes soluciones integrales de calidad.
          </p>
          </div>
          <div className='box-vision'>
          <h2>Visión</h2>
          <p className='text'>
          Proyectar a las empresas hacia el futuro teniendo  en cuenta<br/> los cambios que se producen  en las tendencias del mercado.
          </p>
          </div>
      
      </Container>
      <Container className='seccion4'>
        <Row className='filosofia'>
          <Col>
          <h3>
            Filosofía
          </h3>
          <p>
          El trabajo, constancia, orden y disciplina es el factor del éxito de nuestra empresa.<br/>
          Somos una empresa que nos esforzamos día a día para dar a nuestros clientes lo
          mejor de nosotros a fin de brindarle una buena y cordial atención y así copar todas sus<br/>
          necesidades dentro del rubro en el cual nos hemos desarrollado. <br/> Estamos dispuestos a
          emplear todo nuestro potencial, conocimiento y calidad humana para cumplir nuestros objetivos.
          </p>
          </Col>
          
          <Col className='box-carousel'>
            <Carousel data-bs-theme="dark">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagec1}
                  alt="First slide"
                />
              
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagec2}
                  alt="Second slide"
                />
            
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagec3}
                  alt="Third slide"
                />
              
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagec4}
                  alt="First slide"
                />
              
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagec5}
                  alt="First slide"
                />
              
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={imagec6}
                  alt="First slide"
                />
              
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
      <div className='footer'>
        <Container className='box-container'>
        <div>
          FACTOR FISCAL SOLUCIONES FISCALES
        </div>
        <div>
         All rights reserved ©
        </div>
        </Container>
      </div>
      
    </div>
  );
}

export default App;
